<template>
  <div class="Page">
    <slot name="top"></slot>

    <slot name="drawerButton" :openDrawer="openDrawer">
      <Button
        :id="drawerButtonId"
        class="Page__drawerButton"
        :size="ButtonSizes.FULL"
        @click="openDrawer"
      >
        <slot name="drawerButtonContent">Menu</slot>
      </Button>
    </slot>

    <div class="row">
      <div v-if="hasSidebarSlot" class="Page__sidebar col-md-3">
        <Drawer
          ref="drawer"
          class="Page__drawer"
          :close-label="$t('close')"
          :active-watch-selector="'#' + drawerButtonId"
          :show-filters="true"
        >
          <template #default="{ isActive }">
            <slot name="sidebar" :isActive="isActive"></slot>
          </template>
        </Drawer>
      </div>
      <div class="Page__content col-md-9">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, sizes as ButtonSizes } from '@components/Buttons';
import Drawer from '@components/Drawer';
export default {
  name: 'PageLayout',
  enums: {
    ButtonSizes,
  },
  components: {
    Drawer,
    Button,
  },

  props: {
    drawerButtonId: {
      default: 'pagesidebar_openButton',
      type: String,
    },
  },

  data() {
    return {};
  },

  methods: {
    openDrawer() {
      this.$refs['drawer'].open();
    },
  },

  computed: {
    hasSidebarSlot() {
      return !!this.$slots.sidebar || !!this.$scopedSlots.sidebar;
    },
    hasFooterSlot() {
      return !!this.$slots.footer || !!this.$scopedSlots.footer;
    },
  },
};
</script>

<style lang="scss" scoped>
/* Hide stuff on print  */
.Page__drawerButton,
.Page__sidebar {
  @media print {
    display: none;
  }
}
</style>
