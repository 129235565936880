<template>
  <div :class="['QRCard', `QRCard--${color}`, large && 'QRCard--large']">
    <div class="QRCard__content">
      <ImageComponent v-if="src" :src="src" :alt="title || src" class="QRCard__image" />
      <span v-if="text" class="ft-p2">{{ text }}</span>
    </div>
    <QRCode :value="value" :size="100" class="QRCard__code" />
  </div>
</template>

<script>
import ImageComponent from '@components/ImageComponent';
import QRCode from '@scenes/SelfserviceScene/components/QRCode';

export const Colors = {
  BLUE: 'blue',
  GREY: 'grey',
};

export default {
  name: 'QRCard',

  components: {
    ImageComponent,
    QRCode,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: Colors.BLUE,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.QRCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  border-radius: $border-radius;
  padding: 20px;

  // @include maxBreakpoint($mobileL-numeric) {
  //   gap: 10px;
  //   flex-direction: column-reverse;
  // }

  &--blue {
    background-color: var(--color-prim);
    color: #ffffff;
  }

  &--grey {
    box-shadow: 0 0 0 1px $color-prim-grey;
  }

  .QRCard__content {
    @include maxBreakpoint($mobileL-numeric) {
      display: flex;
      gap: 20px;
      flex-direction: row;
    }
  }

  .QRCard__image {
    width: 120px;
    max-width: 240px;

    @include minBreakpoint($mobileL-numeric) {
      width: 150px;
    }
  }

  &--large .QRCard__image {
    width: 100%;
  }

  .QRCard__code {
    width: 120px;
    flex-shrink: 0;
    border-radius: $border-radius;
  }
}
</style>
