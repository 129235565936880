<template>
  <div class="LatestOrders">
    <div class="LatestOrders__ordersHeader">
      <span class="ft-h4">{{ $t('order.latest_orders') }}</span>
      <router-link :to="{ name: '/selfservice/orders' }" class="LatestOrders__link">
        {{ $t('order.controls.view_all_order') }}
      </router-link>
    </div>
    <Alert
      v-if="ordersContainer.status === Progress.ERROR"
      :level="AlertLevel.ERROR"
      hideIcon
      class="LatestOrders__alert"
    >
      {{ $t('order.message.download_error') }}
    </Alert>
    <SkeletonTransition :ready="ordersReady">
      <template #default>
        <div v-if="ordersContainer.total !== 0" class="LatestOrders__orders">
          <OrderCard
            v-for="order in ordersContainer.orders"
            :key="order.order_number"
            :order="order"
          />
        </div>
        <p v-else class="LatestOrders__noResult">{{ $t('order.message.found_no_orders') }}</p>
      </template>
      <template #skeleton>
        <div class="LatestOrders__orders">
          <!-- <OrderCardSkeleton v-for="i in amount" :key="i" /> -->
          <DetailsCardSkeleton v-for="i in amount" :key="i" />
        </div>
      </template>
    </SkeletonTransition>
  </div>
</template>

<script>
import {
  SkeletonTransition,
  SkeletonGraphic,
  DetailsCardSkeleton,
  OrderCardSkeleton,
} from '@components/Skeleton';
import Alert, { AlertLevel } from '@components/Alert';
import OrderCard from '@scenes/SelfserviceScene/components/OrderCard';
import Progress from '@types/Progress';
import { Filters } from '@scenes/SelfserviceScene/containers/OrdersContainer';

export default {
  name: 'LatestOrders',

  components: {
    SkeletonTransition,
    SkeletonGraphic,
    DetailsCardSkeleton,
    OrderCardSkeleton,
    Alert,
    OrderCard,
  },

  enums: {
    AlertLevel,
    Progress,
  },

  computed: {
    ordersReady() {
      return (
        this.ordersContainer.status === Progress.COMPLETE ||
        this.ordersContainer.status === Progress.ERROR
      );
    },
  },

  created() {
    this.amount = 5;
    this.ordersContainer.setLimit(this.amount);
    this.ordersContainer.search([Filters.LIMIT]);
  },

  inject: {
    ordersContainer: {
      default() {
        console.error('Missing OrdersContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.LatestOrders {
  .LatestOrders__ordersHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .LatestOrders__link {
    color: #bbbbbb;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: $color-prim-black-light;
    }
  }

  .LatestOrders__alert {
    margin-top: 10px;
  }

  .LatestOrders__orders {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }

  .LatestOrders__noResult {
    font-size: 18px;
    color: #aeaeae;
    text-align: center;
    margin-top: 60px;
  }
}
</style>
