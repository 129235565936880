<template>
  <div
    v-observe-resize="onResize"
    :class="[
      'MessageBanner',
      closeable && 'MessageBanner--closeable',
      tall && 'MessageBanner--tall',
      `MessageBanner--${size}`,
    ]"
  >
    <div class="MessageBanner__inner">
      <div class="MessageBanner__content">
        <i v-if="icon" :class="['MessageBanner__icon', icon, 'fa-2x']" />
        <div class="MessageBanner__text">
          <slot name="default" />
        </div>
      </div>
      <div v-if="hasControlsSlot">
        <slot name="controls" :size="size" />
      </div>
      <Close v-if="closeable" class="MessageBanner__close" @click="$emit('close')" />
    </div>
  </div>
</template>

<script>
import { Close } from '@components/Buttons';

export const Icons = {
  EMAIL: 'far fa-envelope',
  MOBILE: 'fas fa-mobile-android-alt',
};

export const Sizes = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export default {
  name: 'MessageBanner',

  components: {
    Close,
  },

  props: {
    icon: {
      type: String,
      default: '',
    },
    closeable: {
      type: Boolean,
      default: false,
    },
    smallMax: {
      type: Number,
      default: 400,
    },
    mediumMax: {
      type: Number,
      default: 600,
    },
  },

  data() {
    return {
      width: this.mediumMax,
      height: 0,
    };
  },

  computed: {
    hasControlsSlot() {
      return !!this.$scopedSlots.controls;
    },
    size() {
      if (this.width <= this.smallMax) return Sizes.SMALL;
      else if (this.width <= this.mediumMax) return Sizes.MEDIUM;
      return Sizes.LARGE;
    },
    tall() {
      return this.height > 120;
    },
  },

  methods: {
    onResize({ width, height }) {
      this.width = width;
      this.height = height;
    },
  },
};
</script>

<style lang="scss" scoped>
.MessageBanner {
  .MessageBanner__inner {
    position: relative;
    display: flex;
    gap: 20px;
    width: 100%;
    background-color: $color-white;
    border: 2px solid $color-prim-blue;
    border-radius: $border-radius;
    padding: 10px 20px;
  }

  .MessageBanner__content {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 16px;
  }

  .MessageBanner__icon {
    color: $color-prim-blue;
  }

  .MessageBanner__text {
    color: $color-prim-black-light;
  }

  .MessageBanner__close {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  &--closeable .MessageBanner__inner {
    padding-right: 60px;
  }

  &--tall .MessageBanner__close {
    top: 10px;
    transform: translateY(0);
  }

  &--large .MessageBanner__inner {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &--medium .MessageBanner__inner {
    flex-direction: column;
    align-items: flex-start;
  }

  &--small {
    .MessageBanner__inner {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .MessageBanner__content {
      font-size: 14px;
    }
  }
}
</style>
