<template>
  <AccountContainer v-slot="{ account }" class="SelfserviceScene">
    <Page>
      <template #top>
        <SelfserviceBanner v-if="account" />
      </template>

      <template #drawerButtonContent>
        <i aria-hidden="true" class="far fa-bars" />{{ $t('selfservice.title') }}
      </template>

      <template #sidebar>
        <Sidebar />
      </template>

      <template #default>
        <DashboardScene v-if="isRoot" />
        <router-view />
      </template>
    </Page>
  </AccountContainer>
</template>

<script>
import AccountContainer from '@containers/AccountContainer';
import Page from '@layouts/Page';
import SelfserviceBanner from './components/SelfserviceBanner';
import Sidebar from './components/Sidebar';
import DashboardScene from './scenes/DashboardScene';
import { gsap } from 'gsap/all';

export default {
  name: 'SelfserviceScene',

  components: {
    AccountContainer,
    Page,
    SelfserviceBanner,
    Sidebar,
    DashboardScene,
  },

  computed: {
    isRoot() {
      return this.$route.name === '/selfservice';
    },
  },

  created() {
    // Disables the automatic refresh - check VAUDeliveryDeadlineCountdown (jslib.js)
    window.disableAutomaticRefresh = true;
  },

  methods: {
    openDrawer() {
      this.$refs['drawer'].open();
    },
    scrollUp() {
      gsap.to(window, { duration: 0.8, delay: 0.2, scrollTo: { y: this.$el, offsetY: 80 } });
    },
  },
};
</script>
