<template>
  <div class="Sidebar">
    <div class="Sidebar__navigation">
      <NavigationList />
    </div>
    <div class="Sidebar__bottom">
      <router-link :to="{ name: '/selfservice/profile' }" class="Sidebar__bottomLink">
        <UserCard
          v-if="accountContainer.account"
          :name="cardName"
          :email="accountContainer.account.email_address"
          clickable
        />
        <i class="Sidebar__bottomIcon far fa-cog" />
      </router-link>
    </div>
  </div>
</template>

<script>
import NavigationList from './components/NavigationList';
import UserCard from '@scenes/SelfserviceScene/components/UserCard';
import Progress from '@types/Progress';

export default {
  name: 'Sidebar',

  components: {
    NavigationList,
    UserCard,
  },

  enums: {
    Progress,
  },

  props: {
    maxLevel: {
      type: Number,
      default: 1,
    },
  },

  computed: {
    cardName() {
      if (this.accountContainer.fullName) {
        return this.accountContainer.fullName;
      } else if (this.accountContainer.account?.company_name) {
        return this.accountContainer.account.company_name;
      }
      return '';
    },
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.Sidebar {
  font-size: 18px;
  background: $color-prim-grey-light;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .Sidebar__navigation {
    padding: 10px;
  }

  .Sidebar__bottom {
    margin-top: 3em;
    padding: 16px;
    border-top: 1px solid $color-prim-grey;
  }

  .Sidebar__bottomLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
  }

  .Sidebar__bottomIcon {
    margin: 0.5em;
  }
}
</style>
