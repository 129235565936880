<template>
  <Collapse>
    <div v-if="!closed && !hasPendingChanges && !verifiedMobile">
      <MessageBanner
        :icon="Icons.MOBILE"
        closeable
        class="SelfserviceBanner"
        @close="closed = true"
      >
        <template #default>
          {{ $t('verify_mobile.banner.text') }}
        </template>
        <template #controls>
          <Button :size="ButtonSizes.MEDIUM2" @click="$refs.dialog.open()">
            {{ $t('profile.controls.verify_mobile_phone_number') }}
          </Button>
        </template>
      </MessageBanner>
      <FormsContainer>
        <PhoneVerificationDialog
          ref="dialog"
          :number="accountContainer.account.mobile_phone_number"
          :countryCode="accountContainer.account.address.country_id"
        />
      </FormsContainer>
    </div>
  </Collapse>
</template>

<script>
import FormsContainer from '@containers/FormsContainer';
import Collapse from '@transitions/Collapse';
import { Button, sizes as ButtonSizes } from '@components/Buttons';
import MessageBanner, { Icons, Sizes } from './components/MessageBanner';
import PhoneVerificationDialog from '@scenes/SelfserviceScene/components/PhoneVerificationDialog';

export default {
  name: 'SelfserviceBanner',

  components: {
    FormsContainer,
    Collapse,
    Button,
    MessageBanner,
    PhoneVerificationDialog,
  },

  enums: {
    ButtonSizes,
    Icons,
    Sizes,
  },

  data() {
    return {
      closed: false,
    };
  },

  computed: {
    hasPendingChanges() {
      return this.accountContainer.pendingOn('mobile_phone_number');
    },
    verifiedMobile() {
      return this.accountContainer.account.verified.includes('mobile_phone_number');
    },
    phoneNumber() {
      return (
        '+' +
        this.accountContainer.account.address.country_id +
        this.accountContainer.account.mobile_phone_number
      );
    },
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.SelfserviceBanner {
  @include spacing-2('margin-bottom');
  @media print {
    display: none;
  }
}
</style>
