<template>
  <div class="QRCode">
    <ImageComponent
      :src="`//dyncdn.thg.dk/QRGenerator/Get?input=${value}&x=${size}&y=${size}`"
      :alt="title"
      aspectRatio="1:1"
      clickable
      @click="$refs.lightbox.open()"
    />
    <Lightbox ref="lightbox" dark>
      <div class="QRCode__zoomImage">
        <ImageComponent
          :src="`//dyncdn.thg.dk/QRGenerator/Get?input=${value}&x=800&y=800`"
          :alt="title"
          aspectRatio="1:1"
        />
      </div>
    </Lightbox>
  </div>
</template>

<script>
import ImageComponent from '@components/ImageComponent';
import Lightbox from '@components/Lightbox';

export default {
  name: 'QRCode',

  components: {
    ImageComponent,
    Lightbox,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 400,
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.QRCode {
  width: 100%;
  user-select: none;

  .QRCode__zoomImage {
    width: 400px;
    background: #ffffff;
    @include spacing-2('padding');
    border-radius: $border-radius;
  }
}
</style>
