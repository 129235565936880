<template>
  <div class="FavoriteStore">
    <BranchCard
      v-if="accountContainer.account.branch"
      :branch="accountContainer.account.branch"
      active
      clickable
    />
    <BranchCardEmpty v-else @click="$refs.favoriteStoreSelect.open()">
      {{ $t('profile.controls.choose_branch') }}
    </BranchCardEmpty>
    <FavoriteStoreSelect ref="favoriteStoreSelect" />
  </div>
</template>

<script>
import { BranchCard, BranchCardEmpty } from '@components/Cards';
import FavoriteStoreSelect from '@components/FavoriteStoreSelect';

export default {
  name: 'FavoriteStore',

  components: {
    BranchCard,
    BranchCardEmpty,
    FavoriteStoreSelect,
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>
