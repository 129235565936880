<template>
  <SelfservicePageLayout class="DashboardScene" :title="$route.meta.title">
    <div class="DashboardScene__container">
      <div class="DashboardScene__cards">
        <QRCard
          v-if="$isEnabled(Features.QR_SCANNERS) && accountContainer.account !== null"
          src="//cdn.thg.dk/DAT/dom/img/qr_scanner_text.png"
          :color="Colors.GREY"
          :value="'C' + accountContainer.account.account_number"
        />

        <FavoriteStore v-if="accountContainer.account !== null" />
      </div>
      <RmaContainer>
        <OrdersContainer>
          <LatestOrders class="DashboardScene__orders" />
        </OrdersContainer>
      </RmaContainer>
    </div>
  </SelfservicePageLayout>
</template>

<script>
import RmaContainer from '@scenes/SelfserviceScene/containers/RmaContainer';
import OrdersContainer from '@scenes/SelfserviceScene/containers/OrdersContainer';
import FavoriteStore from './components/FavoriteStore';
import QRCard, { Colors } from '@scenes/SelfserviceScene/components/QRCard';
import LatestOrders from './components/LatestOrders';
import Features from '@types/Features';

import SelfservicePageLayout from '@scenes/SelfserviceScene/layouts/Page';

export default {
  name: 'DashboardScene',

  components: {
    RmaContainer,
    OrdersContainer,
    FavoriteStore,
    QRCard,
    LatestOrders,
    SelfservicePageLayout,
  },

  enums: {
    Colors,
    Features,
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.DashboardScene {
  .DashboardScene__container {
    display: flex;
    flex-direction: column;
    @include spacing-4('gap');
    @include minBreakpoint($tablet-numeric) {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }

  .DashboardScene__title {
    margin-bottom: 1em;
  }

  .DashboardScene__cards {
    flex: 1;
    display: flex;
    flex-direction: column;
    @include spacing-2('gap');
    max-width: 400px;
  }

  .DashboardScene__orders {
    min-width: 300px;
    flex: 1.2;
  }
}
</style>
