<template>
  <NavigationContainer v-slot="{ selfserviceRoutes }">
    <ul class="NavigationList">
      <template v-for="route in selfserviceRoutes">
        <router-link
          v-slot="{ href, navigate, isExactActive }"
          :key="route.name"
          :to="{ name: route.name }"
        >
          <li>
            <a :href="href" :class="[isExactActive && 'is-active']" @click="navigate">
              <i
                v-if="showIcons && route.meta && route.meta.icon"
                :class="'far fa-' + route.meta.icon"
              />
              {{ route.meta.title }}
              <!-- <span v-if="route.name === 'SelfserviceOrders'" class="NavigationList__count">3</span> -->
            </a>
          </li>
        </router-link>
      </template>
    </ul>
  </NavigationContainer>
</template>

<script>
import NavigationContainer from '@containers/NavigationContainer';
export default {
  name: 'NavigationList',
  components: {
    NavigationContainer,
  },
  props: {
    showIcons: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.NavigationList {
  font-size: 18px;
}

.NavigationList a {
  display: block;
  text-decoration: none;
  position: relative;
}
.NavigationList__count {
  position: absolute;
  right: 1em;
  top: 0.6em;
  border-radius: 50%;
  background: $color-prim-blue;
  font-size: 14px;
  width: 1.6em;
  height: 1.6em;
  color: white;
  display: flex;

  align-content: center;

  justify-content: center;
  padding-top: 2px;
}
.NavigationList a i {
  width: 1.7em;
  display: inline-block;
}
.NavigationList li {
  padding: 0.2em 0;
}
.NavigationList li > a {
  border-radius: 2px;
  padding: 0.5em 0.8em;
}
.NavigationList li > a.is-active {
  background: $color-prim-grey;
}
.NavigationList li > a:hover {
}
</style>
